import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Global.css';
import { PieChart, Pie, Tooltip, Legend, Cell } from 'recharts';
import { Link } from 'react-router-dom';

const AdminUsers = () => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [editingUserId, setEditingUserId] = useState(null);
    const roleDistributionData = users.reduce((acc, user) => {
        acc[user.role] = (acc[user.role] || 0) + 1;
        return acc;
    }, {});

    const roleChartData = Object.keys(roleDistributionData).map(role => ({
        name: role,
        value: roleDistributionData[role]
    }));

    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#A28FD0'];

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await axios.get('https://sxn4468.uta.cloud/wdm/admin.php');
                setUsers(response.data.users);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching data: ", error);
                setLoading(false);
            }
        };

        fetchUsers();
    }, []);

    const handleEdit = (userId) => {
        setEditingUserId(userId);
    };

    const handleDelete = (userId) => {
        // Handle deletion logic here
        // (optional) call API to delete user
        setUsers(users.filter(user => user.user_id !== userId));
    };

    const handleSave = (user) => {
        // Handle save logic here
        // (optional) call API to update user
        setEditingUserId(null);
    };

    const handleChange = (e, userId) => {
        const newUsers = users.map(user => {
            if (user.user_id === userId) {
                return { ...user, [e.target.name]: e.target.value };
            }
            return user;
        });
        setUsers(newUsers);
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="admin-dashboard">
            <header>
                <nav>
                    <ul>
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/settings">Settings</Link></li>
                        <li><Link to="/login">Logout</Link></li>
                        <div className="dashboard-title">
                            User Account Management
                        </div>
                    </ul>
                </nav>
            </header>
            <h2>User List</h2>
            <table>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Role</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map(user => (
                        <tr key={user.user_id}>
                            {editingUserId === user.user_id ? (
                                <>
                                    <td><input type="text" name="name" value={user.name} onChange={(e) => handleChange(e, user.user_id)} /></td>
                                    <td><input type="text" name="email" value={user.email} onChange={(e) => handleChange(e, user.user_id)} /></td>
                                    <td><input type="text" name="role" value={user.role} onChange={(e) => handleChange(e, user.user_id)} /></td>
                                    <td>
                                        <button onClick={() => handleSave(user)}>Save</button>
                                        <button onClick={() => setEditingUserId(null)}>Cancel</button>
                                    </td>
                                </>
                            ) : (
                                <>
                                    <td>{user.name}</td>
                                    <td>{user.email}</td>
                                    <td>{user.role}</td>
                                    <td>
                                        <button onClick={() => handleEdit(user.user_id)}>Edit</button>
                                        <button className="delete" onClick={() => handleDelete(user.user_id)}>Delete</button>
                                    </td>
                                </>
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="admin-dashboard">
                {/* ...existing dashboard content... */}
                <div className="charts-section">
                    <PieChart width={400} height={400}>
                        <Pie
                            dataKey="value"
                            isAnimationActive={false}
                            data={roleChartData}
                            cx={200}
                            cy={200}
                            outerRadius={80}
                            fill="#8884d8"
                            label
                        >
                            {roleChartData.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                        </Pie>
                        <Tooltip />
                        <Legend />
                    </PieChart>
                </div>
            </div>        </div>
    );
};

export default AdminUsers;
