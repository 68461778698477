import React, { Component } from 'react';
import { BrowserRouter as Router } from 'react-router-dom'; // Import BrowserRouter or other router component from 'react-router-dom'
import Customroutes from './components/route';

class App extends Component {
  render() {
    return (
      <Router>
       
       
          <Customroutes />
       
      </Router>
    );
  }
}

export default App;

