import React from 'react';
import { Link } from 'react-router-dom';

const PrivacyPolicy = () => {
  return (
    <div>
      <title>Privacy Policy</title>
      <style>
        {`
          body {
            background: linear-gradient(45deg, rgb(130, 45, 52), #f3f7f6);
            font-family: Arial, sans-serif;
            margin: 20px;
            padding: 0;
          }
          h1 {
            text-align: center;
          }
          p {
            margin-bottom: 20px;
          }
        `}
      </style>
      <h1>Privacy Policy</h1>
      <p>
        This Privacy Policy describes how we collect, use, and disclose information when you use our services.
      </p>
      <p>
        Privacy Policy for EduNeeds<br />
        Information Collection: We collect personal information such as names, email addresses, and usage data when
        you use EduNeeds to create an account or access our services. We may also use cookies and tracking
        technologies to enhance your experience.<br />
        Usage of Information: We use your data for account management, personalization, analytics, communication,
        and legal compliance. This includes customizing your experience, analyzing usage patterns, and sending
        updates.<br />
        Information Sharing: Your information may be shared with third-party service providers to support our
        services or when required by law. In the event of a business transfer, your data may be transferred to the
        new entity.<br />
        Data Security: We take reasonable steps to safeguard your information, but please note that no online data
        transmission method is entirely secure.<br />
        Your Choices: You have the right to access, update, or delete your personal information, opt-out of
        promotional communications, and manage cookies through your browser settings.
      </p>
      <h2>How We Use Your Information</h2>
      <p>
        We use the information we collect to provide, maintain, and improve our services. We may also use this
        information to contact you, provide customer support, and send you updates.
      </p>
      <h2>Disclosure of Your Information</h2>
      <p>
        We may share your information with third-party service providers who assist us in operating our website and
        services. We do not sell or rent your personal information to third parties.
      </p>
      <h2>Cookies</h2>
      <p>We use cookies to collect information and improve our services. You can adjust your browser settings to disable cookies.</p>
      <h2>Changes to This Privacy Policy</h2>
      <p>We may update our Privacy Policy from time to time. Any changes will be posted on this page.</p>
      <Link to="/">Go back to Home</Link>
    </div>
  );
};

export default PrivacyPolicy;

