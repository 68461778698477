import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

const QAOGrades = () => {
    const [grades, setGrades] = useState([]);

    useEffect(() => {
        axios.get('https://sxn4468.uta.cloud/wdm/qao.php')
            .then(response => {
                setGrades(response.data.grades);
            })
            .catch(error => {
                console.error('There was an error fetching the grades', error);
            });
    }, []);

    return (
        <div>
            <header>
                <nav>
                    <ul>
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/settings">Settings</Link></li>
                        <li><Link to="/login">Logout</Link></li>
                        <div className="dashboard-title">
                            Student Performance Analytics
                        </div>
                    </ul>
                </nav>
            </header>
            <div className="grades-report-container">


                <title>Quality Assurance Officer Dashboard</title>
                <link rel="stylesheet" type="text/css" href="Global.css" />

                <h1>Grades Report</h1>
                <table>
                    <thead>
                        <tr>
                            <th>Grade ID</th>
                            <th>Course ID</th>
                            <th>Assessment ID</th>
                            <th>Grade</th>
                            <th>Remarks</th>
                            <th>Instructor ID</th>
                            <th>Student ID</th>
                        </tr>
                    </thead>
                    <tbody>
                        {grades.map((grade, index) => (
                            <tr key={index}>
                                <td>{grade.grade_id}</td>
                                <td>{grade.course_id}</td>
                                <td>{grade.assessment_id}</td>
                                <td>{grade.grade || 'N/A'}</td>
                                <td>{grade.remarks || 'No remarks'}</td>
                                <td>{grade.instructor_id || 'N/A'}</td>
                                <td>{grade.student_id}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default QAOGrades;
