import React, { useState } from 'react';
import './Global.css'; // Import the CSS file

// Placeholder array for courses
const courses = [
    { id: 1, name: "Introduction to Psychology" },
    { id: 2, name: "Principles of Economics" },
    { id: 3, name: "Modern World History" },
    // Add more courses as needed
];

const CreateExam = () => {
    const [exam, setExam] = useState({
        title: '',
        marks: '',
        duration: '',
        examDate: '',
        courseId: ''
    });

    const handleChange = (e) => {
        setExam({ ...exam, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Here, handle the form submission, typically sending data to backend
        console.log(exam);
    };

    return (
        <div className="create-exam-container">
            <h1>Create Exam</h1>
            <form onSubmit={handleSubmit}>
                <label>Title</label>
                <input type="text" name="title" value={exam.title} onChange={handleChange} />

                <label>Marks</label>
                <input type="text" name="type" value={exam.marks} onChange={handleChange} />

                <label>Duration (e.g., 2 hours)</label>
                <input type="text" name="duration" value={exam.duration} onChange={handleChange} />

                <label>Exam Date</label>
                <input type="date" name="examDate" value={exam.examDate} onChange={handleChange} />

                <label>Course</label>
                <select name="courseId" value={exam.courseId} onChange={handleChange}>
                    <option value="">Select a course</option>
                    {courses.map(course => (
                        <option key={course.id} value={course.id}>{course.name}</option>
                    ))}
                </select>

                <button type="submit">Create Exam</button>
            </form>
        </div>
    );
};

export default CreateExam;
