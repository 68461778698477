import React, { useState, useEffect } from 'react';
import axios from 'axios';

const QAOPolicies = () => {
    const [policies, setPolicies] = useState([]);
    const [form, setForm] = useState({ educational_policies: '', qa_processes: '', procedural_guidelines: '', user_id: '' });
    const [editingId, setEditingId] = useState(null);

    // Fetch policies from API
    useEffect(() => {
        fetchPolicies();
    }, []);

    const fetchPolicies = async () => {
        try {
            const response = await axios.get('https://sxn4468.uta.cloud/wdm/qao-policies.php');
            setPolicies(response.data.policies);
        } catch (error) {
            console.error('Error fetching policies:', error);
        }
    };

    const handleChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const url = editingId ? `https://sxn4468.uta.cloud/wdm/qao-policies.php?id=${editingId}` : 'https://sxn4468.uta.cloud/wdm/qao-policies.php';
            const method = editingId ? 'put' : 'post';
            await axios({ method, url, data: form });
            fetchPolicies();
            setForm({ educational_policies: '', qa_processes: '', procedural_guidelines: '', user_id: '' });
            setEditingId(null);
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    const handleEdit = (policy) => {
        setForm(policy);
        setEditingId(policy.id);
    };

    const handleDelete = async (id) => {
        try {
            await axios.delete(`https://sxn4468.uta.cloud/wdm/qao-policies.php?id=${id}`);
            fetchPolicies();
        } catch (error) {
            console.error('Error deleting policy:', error);
        }
    };

    return (
        <div className="policies-processes">
            <div className="content-container">
                <h1>Policies and Processes</h1>

                <form onSubmit={handleSubmit}>
                    <input type="text" name="educational_policies" placeholder="Educational Policies" value={form.educational_policies} onChange={handleChange} />
                    <input type="text" name="qa_processes" placeholder="QA Processes" value={form.qa_processes} onChange={handleChange} />
                    <input type="text" name="procedural_guidelines" placeholder="Procedural Guidelines" value={form.procedural_guidelines} onChange={handleChange} />
                    <input type="number" name="user_id" placeholder="User ID" value={form.user_id} onChange={handleChange} />
                    <button type="submit">{editingId ? 'Update' : 'Add'}</button>
                </form>

                <div>
                    {policies.map(policy => (
                        <div key={policy.id}>
                            <p>{policy.educational_policies}</p>
                            <p>{policy.qa_processes}</p>
                            <p>{policy.procedural_guidelines}</p>
                            <button onClick={() => handleEdit(policy)}>Edit</button>
                            <button onClick={() => handleDelete(policy.id)}>Delete</button>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default QAOPolicies;
