import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './Global.css';

const EnrollCourse = () => {
    const [courseDetails, setCourseDetails] = useState(null);
    const [enrollmentMessage, setEnrollmentMessage] = useState('');
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const course_id = queryParams.get('course_id');

    useEffect(() => {
        const fetchCourseDetails = async () => {
            try {
                if (course_id) {
                    const response = await fetch(`https://sxn4468.uta.cloud/wdm/course_details.php?course_id=${course_id}`);
                    const data = await response.json();
                    setCourseDetails(data.courseDetails[0]);
                }
            } catch (error) {
                console.error('Error fetching course details:', error);
            }
        };

        fetchCourseDetails();
    }, [course_id]);

    const handleEnroll = async () => {
        const user_id = localStorage.getItem('user_id'); // Retrieve user_id from localstorage

        try {
            const response = await fetch('https://sxn4468.uta.cloud/wdm/student.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ user_id, course_id })
            });
            const data = await response.json();
            setEnrollmentMessage(data.message);
        } catch (error) {
            console.error('Error enrolling in course:', error);
        }
    };

    return (
        <div className="course-details">
            <header className="details-header">
                {courseDetails ? <h1>{courseDetails.title}</h1> : <p>Loading...</p>}
            </header>
            <div className="details-content">
                {courseDetails ? (
                    <>
                        <section className="course-description">
                            <h2>Description</h2>
                            <p>{courseDetails.description}</p>
                        </section>

                        <section className="professor-info">
                            <h2>Professor</h2>
                            <p>{courseDetails.professor}</p>
                        </section>

                        <section className="course-materials">
                            <h2>Course Materials</h2>
                            <p>Materials can be listed here if available.</p>
                        </section>

                        <section className="exams-assignments">
                            <h2>Exams & Assignments</h2>
                            <p>{courseDetails.exam_title}</p>
                            <p>{courseDetails.exam_description}</p>
                        </section>
                        {courseDetails && (
                            <button onClick={handleEnroll} className="enroll-button">Enroll in Course</button>
                        )}
                        {enrollmentMessage && <p className="enrollment-message">{enrollmentMessage}</p>}

                    </>
                ) : (
                    <p>Loading course details...</p>
                )}
            </div>
        </div>
    );
};

export default EnrollCourse;
