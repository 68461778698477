import React from 'react';
import { Link } from 'react-router-dom';
import './Global.css';

const PCDashboard = () => {
  return (
    <div>
      <title>Program Co-ordinator Dashboard</title>
      <link rel="stylesheet" type="text/css" href="Global.css" />
      <header>
        <nav>
          <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/messages">Messages</Link></li>
            <li><Link to="/settings">Settings</Link></li>
            <li><Link to="/login">Logout</Link></li>
            <div className="dashboard-title">
              DASHBOARD
            </div>
          </ul>
        </nav>
      </header>
      <div className="dashboard-container">
        <div className="dashboard-container">
          <div className="welcome-text">
            <h2>Welcome, Program Co-ordinator!</h2>
          </div>
          <div className="dashboard-links">
            <ul className="circle-container">
              <li>
                <div className="circle">
                  <Link to="/add-program">Program Overview and Alignment</Link>
                </div>
              </li>
              <li>
                <div className="circle">
                  <Link to="/pc-curriculum">Curriculum Developments and Updates</Link>
                </div>
              </li>

            </ul>

          </div>
        </div>
      </div>
      <footer>
        <div className="footer-links">
          <ul>
            <li><Link to="/privacy">Privacy Policy</Link></li>
            <li><Link to="/faqs">FAQ</Link></li>
            <li><Link to="/about">About Us</Link></li>
            <li><Link to="/contact">Contact Us</Link></li>
          </ul>
        </div>
      </footer>
    </div>
  );
};

export default PCDashboard;


