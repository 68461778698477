import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Global.css';
import { Link } from 'react-router-dom';

const QAOFeedback = () => {
    const [feedback, setFeedback] = useState([]);
    const [newFeedback, setNewFeedback] = useState('');

    // Function to fetch feedback data from the API
    const fetchFeedback = () => {
        axios.get('https://sxn4468.uta.cloud/wdm/qao-feedback.php')
            .then(response => {
                setFeedback(response.data.feedback);
            })
            .catch(error => {
                console.error('Error fetching feedback', error);
            });
    };

    useEffect(() => {
        fetchFeedback();
    }, []);

    // Function to handle submitting new feedback
    const submitFeedback = () => {
        if (newFeedback.trim() === '') {
            alert('Please enter your feedback.');
            return;
        }

        axios.post('https://sxn4468.uta.cloud/wdm/qao-feedback.php', {
            user_id: 123, // Replace with the actual user ID
            feedback: newFeedback,
        })
            .then(response => {
                // Clear the input field and refresh feedback data
                setNewFeedback('');
                fetchFeedback();
            })
            .catch(error => {
                console.error('Error submitting feedback', error);
            });
    };

    return (
        <div>
            <header>
                <nav>
                    <ul>
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/settings">Settings</Link></li>
                        <li><Link to="/login">Logout</Link></li>
                        <div className="dashboard-title">
                            Student Performance Analytics
                        </div>
                    </ul>
                </nav>
            </header>
            <div className="feedback-container">
                <h1>Feedback</h1>

                <div className="feedback-list">
                    <ul>
                        {feedback.map(item => (
                            <li key={item.id}>
                                <strong>User ID:</strong> {item.user_id}
                                <br />
                                <strong>Feedback:</strong> {item.feedback}
                                <br />
                                <strong>Created At:</strong> {item.created_at}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>

    );
};

export default QAOFeedback;
