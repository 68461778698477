import React from 'react';
import { Link } from 'react-router-dom';

const FAQs = () => {
  return (
    <div>
      <title>FAQs</title>
      <style>
        {`
          body {
            font-family: Arial, sans-serif;
            margin: 20px;
            padding: 0;
          }
          h1 {
            text-align: center;
          }
          ul {
            list-style: none;
            padding: 0;
          }
          li {
            margin-bottom: 20px;
            border: 1px solid #ddd;
            padding: 10px;
            background-color: #f9f9f9;
          }
        `}
      </style>
      <h1>Frequently Asked Questions</h1>
      <ul>
        <li className="q-how-can-i-access">
          <strong>Q: How can I access courses on Eduneeds Academic?</strong><br />
          A: To access courses on Eduneeds Academic, simply sign up for an account and select the "Student" role during registration. Once registered, log in to your account, and you'll have access to our wide range of courses and educational resources.
        </li>
        <li className="q-can-i-interact">
          <strong>Q: Can I interact with instructors and fellow students on Eduneeds Academic?</strong><br />
          A: Yes, Eduneeds Academic provides opportunities for interaction with both instructors and fellow students. You can engage in discussions, ask questions, and collaborate with your instructors and peers through our platform's communication features, creating a rich and interactive learning experience.
        </li>
        <li className="q-is-eduneeds">
          <strong>Q: Is Eduneeds Academic free to use?</strong><br />
          A: Yes, Eduneeds Academic offers free access to our platform. Users can sign up and access a selection of free courses without any subscription fees.
        </li>
        {/* Add more FAQs as needed */}
      </ul>
      <Link to="/home">Go back to Home</Link>
    </div>
  );
};

export default FAQs;


