import React, { useState, useEffect } from 'react';
import './Global.css';
import { useNavigate, useLocation } from 'react-router-dom';

const EditCourse = () => {
    const [course, setCourse] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const course_id = queryParams.get('course_id');
    const navigate = useNavigate();

    const [grades, setGrades] = useState([]);
    const [selectedGrades, setSelectedGrades] = useState({});

    useEffect(() => {
        const fetchCourseDetails = async () => {
            try {
                if (course_id) {
                    const response = await fetch(`https://sxn4468.uta.cloud/wdm/course_details.php?course_id=${course_id}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });

                    const data = await response.json();

                    if (data && data.courseDetails && data.courseDetails.length > 0) {
                        setCourse(data.courseDetails[0]);
                        const assessment_id = course ? course.assessment_id : null;

                    } else {
                        console.error('Course details not found');
                    }
                }
            } catch (error) {
                console.error('Error fetching course details:', error);
            }
        };
        const fetchGrades = async () => {
            try {
                const response = await fetch(`https://sxn4468.uta.cloud/wdm/student_grades.php?course_id=${course_id}`);
                const data = await response.json();
                setGrades(data.grades);
                initializeSelectedGrades(data.grades);
            } catch (error) {
                console.error('Error fetching grades:', error);
            }
        };

        fetchGrades();
        fetchCourseDetails();
    }, [course_id]);
    const handleRemarksChange = (gradeId, value) => {
        const updatedGrades = grades.map(grade => {
            if (grade.grade_id === gradeId) {
                return { ...grade, remarks: value };
            }
            return grade;
        });
        setGrades(updatedGrades);
    };

    const initializeSelectedGrades = (grades) => {
        const initialGrades = {};
        grades.forEach(grade => {
            initialGrades[grade.grade_id] = grade.grade || '';
        });
        setSelectedGrades(initialGrades);
    };

    const handleGradeChange = (gradeId, value) => {
        setSelectedGrades({ ...selectedGrades, [gradeId]: value });
    };

    const toggleEditMode = () => {
        setEditMode(prevEditMode => !prevEditMode);
    };


    const handleInputChange = (e) => {
        setCourse({ ...course, [e.target.name]: e.target.value });
    };
    const handleAddAssessmentClick = () => {
        navigate(`/create-assessment?course_id=${course_id}`);
    };
    const handleAddExamClick = () => {
        navigate(`/create-exam?course_id=${course_id}`);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await fetch(`https://sxn4468.uta.cloud/wdm/courses.php?course_id=${course_id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(course)
            });
            // Handle response as necessary
        } catch (error) {
            console.error('Error updating course:', error);
        }
        setEditMode(false);
    };

    // const handleSaveGrades = async () => {
    //     const instructorId = localStorage.getItem("user_id");
    //     let allGradesSaved = true;

    //     for (const gradeId in selectedGrades) {
    //         if (selectedGrades.hasOwnProperty(gradeId)) {
    //             const gradeValue = selectedGrades[gradeId];
    //             const grade = grades.find(g => g.grade_id === parseInt(gradeId));
    //             const requestBody = {
    //                 course_id,
    //                 assessment_id: grade.assessment_id, // Assuming assessment_id is part of your grade object
    //                 remarks: grade.remarks,
    //                 instructor_id: instructorId,
    //                 student_id: grade.student_id,
    //                 grade: gradeValue, // Add the grade value to the request
    //             };

    //             try {
    //                 const response = await fetch('http://sxn4468.uta.cloud/wdm/student_grades.php', {
    //                     method: 'POST', // Assuming the method is POST
    //                     headers: {
    //                         'Content-Type': 'application/json',
    //                     },
    //                     body: JSON.stringify(requestBody),
    //                 });
    //                 const result = await response.json();
    //                 if (!result.success) {
    //                     allGradesSaved = false;
    //                     console.error('Error saving grade for student:', grade.student_id);
    //                 }
    //             } catch (error) {
    //                 allGradesSaved = false;
    //                 console.error('Error saving grades:', error);
    //             }
    //         }
    //     }

    //     if (allGradesSaved) {
    //         alert("All grades saved successfully!");
    //     } else {
    //         alert("Some grades could not be saved. Please try again.");
    //     }
    // };


    const handleSaveGrades = async () => {
        const instructorId = localStorage.getItem("user_id"); // Retrieve instructor_id from local storage
        let allGradesSaved = true;

        for (const gradeId in selectedGrades) {
            if (selectedGrades.hasOwnProperty(gradeId)) {
                const gradeValue = selectedGrades[gradeId];
                // Find the grade object by grade_id from the grades array
                const grade = grades.find(g => g.grade_id === gradeId);
                if (!grade) {
                    console.error(`Grade with id ${gradeId} not found`);
                    continue;
                }
                const requestBody = {
                    course_id,
                    assessment_id: grade.assessment_id, // Use assessment_id from the grade object
                    remarks: grade.remarks,
                    instructor_id: instructorId,
                    student_id: grade.student_id,
                    grade: gradeValue, // Add the grade value to the request
                };

                try {
                    const response = await fetch('https://sxn4468.uta.cloud/wdm/student_grades.php', {
                        method: 'POST', // Assuming the method is POST
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(requestBody),
                    });
                    const result = await response.json();
                    if (!result.success) {
                        allGradesSaved = false;
                        console.error('Error saving grade for student:', grade.student_id);
                    }
                } catch (error) {
                    allGradesSaved = false;
                    console.error('Error saving grades:', error);
                }
            }
        }

        if (allGradesSaved) {
            alert("All grades saved successfully!");
        } else {
            alert("Some grades could not be saved. Please try again.");
        }
    };

    if (!course) {
        return <p>Loading...</p>;
    }

    return (
        <div className="course-details">
            {editMode ? (
                <input type="text" name="title" value={course.title} onChange={handleInputChange} />
            ) : (
                <h1>{course.title}</h1>
            )}
            <div className="details-content">
                {editMode ? (
                    <form onSubmit={handleSubmit}>
                        <section className="course-description">
                            <h2>Description</h2>
                            <textarea name="description" value={course.description} onChange={handleInputChange} />
                        </section>

                        <section className="professor-info">
                            <h2>Professor</h2>
                            <input type="text" name="professor" value={course.professor} onChange={handleInputChange} />
                        </section>
                        <button type="submit">Save Changes</button>
                        <button onClick={toggleEditMode}>Cancel</button>
                    </form>
                ) : (
                    <>
                        <section className="course-description">
                            <h2>Description</h2>
                            <p>{course.description}</p>
                        </section>

                        <section className="professor-info">
                            <h2>Professor</h2>
                            <p>{course.professor}</p>
                        </section>

                        <section className="professor-info">
                            <h2>Faculty</h2>
                            <p>{course.faculty}</p>
                        </section>

                        <section className="professor-info">
                            <h2>Assessments & Exams</h2>
                            <p>{course.exam_title}</p>
                            <p>{course.exam_description}</p>

                        </section>
                        <section className="grades-section">
                            <h2>Grades</h2>
                            {grades.map(grade => (
                                <div key={grade.grade_id} className="grade-card">
                                    <div className="grade-details">
                                        <span>Student ID: {grade.student_id}</span>
                                        <select
                                            value={selectedGrades[grade.grade_id]}
                                            onChange={(e) => handleGradeChange(grade.grade_id, e.target.value)}
                                        >
                                            <option value="">Select Grade</option>
                                            <option value="A">A</option>
                                            <option value="B">B</option>
                                            <option value="C">C</option>
                                            <option value="D">D</option>
                                            <option value="F">F</option>
                                        </select>
                                    </div>
                                    <textarea
                                        placeholder="Enter review"
                                        value={grade.remarks || ''}
                                        onChange={(e) => handleRemarksChange(grade.grade_id, e.target.value)}
                                    />
                                </div>
                            ))}
                            <button onClick={handleSaveGrades}>Save Grades</button>
                        </section>

                        <div style={{ display: 'flex', gap: '10px' }}>
                            <button onClick={toggleEditMode} style={{ padding: '10px' }}>Edit Course</button>
                            <button onClick={handleAddAssessmentClick} style={{ padding: '10px' }}>Add Assessment or Exam</button>
                        </div>

                    </>
                )}
            </div>
        </div>
    );
};

export default EditCourse;
