import React, { useState, useEffect } from 'react';
import './Global.css';

const CourseDetails = () => {
    const [courseDetails, setCourseDetails] = useState(null);
    const course_id = new URLSearchParams(window.location.search).get('course_id');
    const student_id = localStorage.getItem('user_id');
    const [grades, setGrades] = useState([]); // New state for grades
    const [examResponse, setExamResponse] = useState('');

    useEffect(() => {
        const fetchCourseDetails = async () => {
            try {
                if (course_id) {
                    const response = await fetch(`https://sxn4468.uta.cloud/wdm/course_details.php?course_id=${course_id}`);
                    const data = await response.json();
                    setCourseDetails(data.courseDetails[0]);
                }
            } catch (error) {
                console.error('Error fetching course details:', error);
            }
        };

        const fetchGrades = async () => {
            try {
                const response = await fetch(`https://sxn4468.uta.cloud/wdm/student_grades.php?course_id=${course_id}`);
                const data = await response.json();
                const studentGrades = data.grades.filter(grade => grade.student_id === student_id.toString());
                setGrades(studentGrades);
            } catch (error) {
                console.error('Error fetching grades:', error);
            }
        };

        fetchGrades();
        fetchCourseDetails();
    }, [course_id, student_id]);
    const renderGrades = () => {
        return grades.map(grade => (
            <div key={grade.grade_id} className="grade-card">
                <p>Assessment ID: {grade.assessment_id}</p>
                <p>Grade: {grade.grade || 'Not graded yet'}</p>
                <p>Remarks: {grade.remarks || 'No remarks'}</p>
            </div>
        ));
    };
    const handleTakeExam = async () => {
        try {
            const response = await fetch('https://sxn4468.uta.cloud/wdm/student_grades.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    course_id: courseDetails.course_id,
                    assessment_id: courseDetails.assessment_id,
                    student_id: student_id,
                }),
            });
            const data = await response.json();
            if (data.success) {
                setExamResponse('Exam started successfully. Good luck!');
            } else {
                setExamResponse('Failed to start the exam. Please try again.');
            }
        } catch (error) {
            console.error('Error while taking the exam:', error);
            setExamResponse('An error occurred. Please try again later.');

        }
    };

    return (
        <div className="course-details">
            <header className="details-header">
                {courseDetails ? <h1>{courseDetails.title}</h1> : <p>Loading...</p>}
            </header>
            <div className="details-content">
                {courseDetails ? (
                    <>
                        <section className="course-description">
                            <h2>Description</h2>
                            <p>{courseDetails.description}</p>
                        </section>

                        <section className="professor-info">
                            <h2>Professor</h2>
                            <p>{courseDetails.professor}</p>
                        </section>

                        <section className="course-materials">
                            <h2>Course Materials</h2>
                            <p>Materials can be listed here if available.</p>
                        </section>

                        <section className="exams-assignments">
                            <h2>Exams & Assignments</h2>
                            <p>{courseDetails.exam_title}</p>
                            <p>{courseDetails.exam_description}</p>
                            <button onClick={handleTakeExam}>Take Exam</button>
                            {examResponse && <p className="exam-response">{examResponse}</p>}
                        </section>
                        <section className="grades-section">
                            <h2>Your Grades</h2>
                            {grades.length ? renderGrades() : <p>No grades available.</p>}
                        </section>
                    </>
                ) : (
                    <p>Loading course details...</p>
                )}
            </div>
        </div>
    );
};

export default CourseDetails;
