import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Home from './index';
import About from './about';
import ContactUs from './contact';
import RegistrationPage from './registration';
import LoginPage from './Login';
import PrivacyPolicy from './privacy';
import FAQs from './faq';
import ChatPage from './chat';
import Settings from './settings';
import Notification from './notification';
import StudentDashboard from './student-dashboard';
import InstructorDashboard from './instructor-dashboard';
import Instructor3 from './instructor-creation';
import Instructor4 from './int4';
import AdminDashboard from './admin-dashboard';
import AdminAddCourseProgram from './admin-add-course-program';
import AdminUserManagement from './admin-user-management';
import Admin3 from './Admin3';
import Admin4 from './Admin4';
import PCDashboard from './pc-dashboard';
import Program2 from './PC2';
import Program3 from './PC3';
import Quality3 from './QAO3';
import AcademicProgramOverview from './academic-program-overview';
import CourseCatalog from './course-catalog';
import EnrolledCourses from './enrolled-courses';
import CourseDetails from './course-details';
import InstructorCourses from './instructor-courses';
import EditCourse from './edit-course';
import CreateAssessment from './create-assessment';
import CreateExam from './create-exam';
import InstructorGrades from './instructor-grades';
import AddCourse from './AddCourse';
import AddProgram from './AddProgram';
import AdminUsers from './admin-users';
import ManageEnrollment from './manage-enrollment';
import PCCurriculum from './pc-curriculum';
import QADashboard from './qao-dashboard';
import QAOPolicies from './qao-policies';
import MessagesPage from './messages';
import EnrollCourse from './enroll_course';
import CoursesPage from './admin-courses';
import QAOAnalytics from './qao-analytics'
import QAOGrades from './qao-grades';
import QAOReports from './qao-reports';
import QAOFeedback from './qao-feedback';
import ForgotPasswordPage from './forgetpassword';


const Customroutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/home" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/contact" element={<ContactUs />} />
      <Route path="/registration" element={<RegistrationPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/forget" element={<ForgotPasswordPage />} />
      <Route path="/privacy" element={<PrivacyPolicy />} />
      <Route path="/faq" element={<FAQs />} />
      <Route path="/chat" element={<ChatPage />} />
      <Route path="/settings" element={<Settings />} />
      <Route path="/notification" element={<Notification />} />
      <Route path="/student-dashboard" element={<StudentDashboard />} />
      <Route path="/instructor-dashboard" element={<InstructorDashboard />} />
      <Route path="/instructor-creation" element={<Instructor3 />} />
      <Route path="/instructor4" element={<Instructor4 />} />
      <Route path="/admin-dashboard" element={<AdminDashboard />} />
      <Route path="/admin-add-course-program" element={<AdminAddCourseProgram />} />
      <Route path="/admin-user-management" element={<AdminUserManagement />} />
      <Route path="/admin3" element={<Admin3 />} />
      <Route path="/admin4" element={<Admin4 />} />
      <Route path="/pc-dashboard" element={<PCDashboard />} />
      <Route path="/pc2" element={<Program2 />} />
      <Route path="/pc3" element={<Program3 />} />
      <Route path="/qao-dashboard" element={<QADashboard />} />
      <Route path="/qao3" element={<Quality3 />} />
      <Route path="/academic-program-overview" element={<AcademicProgramOverview />} />
      <Route path="/course-catalog" element={<CourseCatalog />} />
      <Route path="/enrolled-courses" element={<EnrolledCourses />} />
      <Route path="/course-details" element={<CourseDetails />} />
      <Route path="/instructor-courses" element={<InstructorCourses />} />
      <Route path="/edit-course" element={<EditCourse />} />
      <Route path="/create-assessment" element={<CreateAssessment />} />
      <Route path="/create-exam" element={<CreateExam />} />
      <Route path="/instructor-grades" element={<InstructorGrades />} />
      <Route path="/add-course" element={<AddCourse />} />
      <Route path="/add-program" element={<AddProgram />} />
      <Route path="/admin-users" element={<AdminUsers />} />
      <Route path="/manage-enrollment" element={<ManageEnrollment />} />
      <Route path="/pc-curriculum" element={<PCCurriculum />} />
      <Route path="/qao-policies" element={<QAOPolicies />} />
      <Route path="/messages" element={<MessagesPage />} />
      <Route path="/enroll-course" element={<EnrollCourse />} />
      <Route path="/admin-courses" element={<CoursesPage />} />
      <Route path="/qao-analytics" element={<QAOAnalytics />} />
      <Route path="/qao-grades" element={<QAOGrades />} />
      <Route path="/qao-reports" element={<QAOReports />} />
      <Route path="/qao-feedback" element={<QAOFeedback />} />

    </Routes>
  );
};

export default Customroutes;
