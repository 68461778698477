import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Global.css';

const EnrolledCourses = () => {
    const [enrolledCourses, setEnrolledCourses] = useState([]);
    const [hasNoCourses, setHasNoCourses] = useState(false);

    useEffect(() => {
        const fetchEnrolledCourses = async () => {
            try {
                const user_id = localStorage.getItem('user_id');
                const response = await fetch(`https://sxn4468.uta.cloud/wdm/enrolled_courses.php?user_id=${user_id}`);
                const data = await response.json();

                if (data.error) {
                    setHasNoCourses(true);
                } else {
                    setEnrolledCourses(data.enrolledCourses);
                }
            } catch (error) {
                console.error('Error fetching enrolled courses:', error);
            }
        };

        fetchEnrolledCourses();
    }, []);

    return (
        <div className="course-catalog">
            <header className="catalog-header">
                <h1>Enrolled Courses</h1>
            </header>
            <div className="course-list">
                {hasNoCourses ? (
                    <p>No courses enrolled.</p>
                ) : (
                    enrolledCourses.map((course) => (
                        <Link
                            to={{
                                pathname: '/course-details',
                                search: `?course_id=${course.course_id}`,
                            }}
                            className="course-link"
                            key={course.course_id}
                        >
                            <div className="course-card">
                                <h2 className="course-title">{course.title}</h2>
                                <p className="course-description">{course.description}</p>
                                <p className="course-professor">Faculty: {course.faculty}</p>
                            </div>
                        </Link>
                    ))
                )}
            </div>
        </div>
    );
};

export default EnrolledCourses;
