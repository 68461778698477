import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Global.css';

const ManageEnrollment = () => {
    const [students, setStudents] = useState([]);
    const [allPrograms, setAllPrograms] = useState([]);
    const [allCourses, setAllCourses] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://sxn4468.uta.cloud/wdm/admin-student.php');
                setStudents(response.data.students.map(student => ({
                    ...student,
                    courses: student.courses ? student.courses.split(',') : []
                })));
                setAllPrograms(response.data.allPrograms);
                setAllCourses(response.data.allCourses);

                // Log the fetched data for debugging
                console.log("Fetched Students:", response.data.students);
                console.log("Fetched Programs:", response.data.allPrograms);
                console.log("Fetched Courses:", response.data.allCourses);
            } catch (error) {
                console.error("Error fetching data: ", error);
            }
        };

        fetchData();
    }, []);
    const handleProgramChange = (studentId, newProgram) => {
        const updatedStudents = students.map(student => {
            if (student.id === studentId) {
                return { ...student, program: newProgram };
            }
            return student;
        });
        setStudents(updatedStudents);
    };

    const handleCoursesChange = (studentId, newCourses) => {
        const updatedStudents = students.map(student => {
            if (student.id === studentId) {
                return { ...student, courses: newCourses.split(',').map(course => course.trim()) };
            }
            return student;
        });
        setStudents(updatedStudents);
    };

    return (
        <div className="manage-enrollment-container">
            <h1>Manage Student Enrollments</h1>
            <table>
                <thead>
                    <tr>
                        <th>Student</th>
                        <th>Program</th>
                        <th>Courses</th>
                    </tr>
                </thead>
                <tbody>
                    {students.map(student => (
                        <tr key={student.id}>
                            <td>{student.name}</td>
                            <td>
                                <select
                                    value={student.program}
                                    onChange={(e) => handleProgramChange(student.id, e.target.value)}
                                >
                                    {allPrograms.map(program => (
                                        <option key={program} value={program}>{program}</option>
                                    ))}
                                </select>
                            </td>
                            <td>
                                <input
                                    type="text"
                                    value={student.courses.join(', ')}
                                    onChange={(e) => handleCoursesChange(student.id, e.target.value)}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default ManageEnrollment;