import React from 'react';
// import { Link } from 'react-router-dom';

const Settings = () => {
  return (
    <div>
      <title>Settings</title>
      <style dangerouslySetInnerHTML={{
        __html: `
          body {
              background: linear-gradient(45deg, rgb(130, 45, 52), #f3f7f6);
              font-family: Arial, sans-serif;
              margin: 20px;
              padding: 0;
          }
          h1 {
              text-align: center;
          }
          p {
              margin-bottom: 20px;
          }
          label {
              display: block;
              margin-top: 10px;
          }
          input[type="checkbox"] {
              margin-right: 5px;
          }
        `
      }} />
      {/* Academic Account Settings */}
      <div className="account-settings" style={{
        position: 'absolute',
        top: '0%',
        left: 300,
        width: 700,
        textAlign: 'center',
        fontSize: 18,
        color: '#FFFFFF',
        backgroundColor: 'rgba(106, 41, 52, 0.8)',
        padding: 30,
        borderRadius: 15,
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
      }}>
        <h2 style={{
          fontSize: 28,
          color: '#FFD700',
          marginBottom: 20,
        }}>Academic Account Settings</h2>
        <p style={{
          fontSize: 20,
        }}>Welcome to your academic institution's account settings. Here, you can customize your preferences to enhance your academic experience.</p>
        <ul style={{
          listStyleType: 'none',
          padding: 0,
          marginTop: 20,
          textAlign: 'left',
        }}>
          <li style={{
            fontSize: 20,
            marginBottom: 10,
          }}>
            <strong>Email:</strong>
            <input type="text" placeholder="Add/Edit" style={{
              border: 'none',
              backgroundColor: 'transparent',
              fontSize: 18,
              color: '#FFFFFF',
            }} />
          </li>
          <li style={{
            fontSize: 20,
            marginBottom: 10,
          }}>
            <strong>Privacy:</strong>
            <label style={{
              marginLeft: 10,
            }}>
              <input type="checkbox" style={{
                marginRight: 5,
              }} /> Public
            </label>
            <label style={{
              marginLeft: 10,
            }}>
              <input type="checkbox" style={{
                marginRight: 5,
              }} /> Private
            </label>
          </li>
          <li style={{
            fontSize: 20,
            marginBottom: 10,
          }}>
            <strong>Add Profile Photo:</strong>
            <input type="file" style={{
              marginLeft: 10,
            }} />
          </li>
          <li style={{
            fontSize: 20,
            marginBottom: 10,
          }}>
            <strong>Security:</strong>
            <ul style={{
              listStyleType: 'circle',
              marginTop: 10,
            }}>
              <li>Data Sharing
                <label style={{
                  marginLeft: 10,
                }}>
                  <input type="checkbox" style={{
                    marginRight: 5,
                  }} /> Enabled
                </label>
              </li>
              <li>Visibility
                <label style={{
                  marginLeft: 10,
                }}>
                  <input type="checkbox" style={{
                    marginRight: 5,
                  }} /> Enabled
                </label>
              </li>
            </ul>
          </li>
        </ul>
        <button style={{
          marginTop: 20,
          padding: '10px 20px',
          fontSize: 18,
          backgroundColor: '#FFD700',
          color: '#000',
          border: 'none',
          borderRadius: 5,
          cursor: 'pointer',
        }}>Get Started</button>
      </div>
    </div>
  );
};

export default Settings;

