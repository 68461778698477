import React from 'react';
import { Link } from 'react-router-dom';

const About = () => {
  const customStyles = `
    body {
        background: linear-gradient(45deg, rgb(130, 45, 52), #f3f7f6);
        font-family: Arial, sans-serif;
        margin: 20px;
        padding: 0;
    }
    h1 {
        text-align: center;
    }
    p {
        margin-bottom: 20px;
    }
    .centered-image {
        display: block;
        margin: 0 auto;
    }
    .AboutUs {
        text-align: center;
        margin-bottom: 30px;
    }
    .AboutUs-col {
        max-width: 800px;
        margin: 0 auto;
        text-align: left;
    }
  `;

  return (
    <div>
      <style dangerouslySetInnerHTML={{ __html: customStyles }} />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <title>About Us Design</title>
      <header>
        <Link to="/" className="logo-link">
          <img src="/Images/logo2.png" alt="Logo" className="logo"  />
        </Link>
        <div className="AboutUs" id="AboutUs">          
        </div>
      </header>
      <h3> About Us </h3>
      <p> Eduneeds is the world's best academic program, dedicated to serving the education industry since 1999. It aims to provide a phenomenal educational experience to all. We prioritize diversity and inclusion and create an environment where everyone has a chance to thrive. Our mission is to provide students and faculty with a high-quality academic experience. We focus on making a positive impact on their lives and careers by prioritizing each individual's needs. </p>
      <div className="row">
        <div className="AboutUs-col">
          <h3> Our Goals </h3>
          <p> We aim to provide the best experience to our students and faculty. We want to equip our students with the best curriculum and prepare them so that they can tackle the real world with ease and confidence. Our mission is to provide a safe environment where everyone can communicate and view their respective courses with ease and no hassle.</p>
          <h3> Services Provided </h3>
          <p> Eduneeds is a learning platform where students, instructors, administrators, and all staff can come together and communicate and interact on one single platform. Students can view their grades, chat with instructors, see their courses, and much more. Instructors, on the other hand, can update their grades, create exams, and provide feedback. Admins, quality assurance officers, and program coordinators can all interact and control different aspects of the program based on what they have access to.</p>
          <img src="/Images/goals.png" alt="Goals Illustration" className="centered-image" width={500} height={300} />
        </div>
      </div>
    </div>
  );
};

export default About;
