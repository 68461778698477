import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Global.css';

const AddProgram = () => {
    const [programs, setPrograms] = useState([]);
    const [program, setProgram] = useState({
        program_id: '',
        title: '',
        description: '',
        curriculum_overview: '',
        requirements: '',
        duration: '',
        faculty: '',
        courses_id: ''
    });
    const [editing, setEditing] = useState(false);

    useEffect(() => {
        fetchPrograms();
    }, []);

    const fetchPrograms = async () => {
        try {
            const response = await axios.get('https://sxn4468.uta.cloud/wdm/pc.php');
            setPrograms(response.data.programs);
        } catch (error) {
            console.error('Error fetching programs:', error);
        }
    };

    const handleChange = (e) => {
        setProgram({ ...program, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const url = editing ? `https://sxn4468.uta.cloud/wdm/pc.php?id=${program.program_id}` : 'https://sxn4468.uta.cloud/wdm/pc.php';
        const method = editing ? 'put' : 'post';

        try {
            await axios({ method, url, data: program });
            setProgram({
                program_id: '',
                title: '',
                description: '',
                curriculum_overview: '',
                requirements: '',
                duration: '',
                faculty: '',
                courses_id: ''
            });
            setEditing(false);
            fetchPrograms();
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    const handleEdit = (programData) => {
        setProgram({ ...programData });
        setEditing(true);
    };

    const handleDelete = async (programId) => {
        try {
            await axios.delete(`https://sxn4468.uta.cloud/wdm/pc.php?id=${programId}`);
            fetchPrograms();
        } catch (error) {
            console.error('Error deleting program:', error);
        }
    };

    return (
        <div className="add-program-container">
            <h1>{editing ? 'Edit Program' : 'Add New Program'}</h1>
            <form onSubmit={handleSubmit}>
                {/* Updated form fields to match the structure of your programs */}
                <label>Title</label>
                <input type="text" name="title" value={program.title} onChange={handleChange} />

                <label>Description</label>
                <textarea name="description" value={program.description} onChange={handleChange}></textarea>

                <label>Curriculum Overview</label>
                <textarea name="curriculum_overview" value={program.curriculum_overview} onChange={handleChange}></textarea>

                <label>Requirements</label>
                <textarea name="requirements" value={program.requirements} onChange={handleChange}></textarea>

                <label>Duration</label>
                <input type="text" name="duration" value={program.duration} onChange={handleChange} />

                <button type="submit">{editing ? 'Update Program' : 'Add Program'}</button>
            </form>

            <div className="programs-list">
                {programs.map((prog) => (
                    <div key={prog.program_id} className="program-item">
                        <h3>{prog.title}</h3>
                        <p>{prog.description}</p>
                        <p>{prog.curriculum_overview}</p>
                        <p>{prog.requirements}</p>

                        <button onClick={() => handleEdit(prog)}>Edit</button>
                        <button onClick={() => handleDelete(prog.program_id)}>Delete</button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default AddProgram;
