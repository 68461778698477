import React, { useState, useEffect } from 'react';
import './Global.css';
import queryString from 'query-string';

const CreateAssessment = () => {
    const instructor_id = localStorage.getItem('user_id');

    const [assessment, setAssessment] = useState({
        title: '',
        description: '',
        type: '',
        due_date: '',
        course_id: '',
        professor_id: instructor_id
    });

    useEffect(() => {
        const queryParams = queryString.parse(window.location.search);

        const course_id = queryParams.course_id;
        console.log("Retrieved course_id:", course_id);

        setAssessment(prevState => ({
            ...prevState,
            course_id: course_id
        }));
    }, []);

    const handleChange = (e) => {
        setAssessment({ ...assessment, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch(`https://sxn4468.uta.cloud/wdm/assessment.php?course_id=${assessment.course_id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(assessment),
            });

            if (response.ok) {
                console.log('Assessment created successfully');
            } else {
                console.error('Failed to create assessment');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <div className="create-assessment-container">
            <h1>Create Assessment or Exam</h1>
            <form onSubmit={handleSubmit}>
                <label>Title</label>
                <input type="text" name="title" value={assessment.title} onChange={handleChange} />

                <label>Description</label>
                <textarea name="description" value={assessment.description} onChange={handleChange}></textarea>

                <label>Due Date</label>
                <input type="date" name="due_date" value={assessment.due_date} onChange={handleChange} />


                <button type="submit">Create Assessment</button>
            </form>
        </div>
    );
};

export default CreateAssessment;
