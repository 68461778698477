import React, { useState } from 'react';
import './Global.css';

const AddCourse = () => {
    const [course, setCourse] = useState({
        title: '',
        description: '',
        professor: '',
        materials: [],
        exams: ''
    });

    const handleInputChange = (e) => {
        setCourse({ ...course, [e.target.name]: e.target.value });
    };

    const handleMaterialChange = (e, index) => {
        const newMaterials = [...course.materials];
        newMaterials[index] = { ...newMaterials[index], title: e.target.value };
        setCourse({ ...course, materials: newMaterials });
    };

    const addMaterialField = () => {
        setCourse({ ...course, materials: [...course.materials, { id: Date.now(), title: "" }] });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(course);
    };

    return (
        <div className="course-details">
            <input type="text" name="title" placeholder="Course Title" value={course.title} onChange={handleInputChange} />
            <form onSubmit={handleSubmit}>
                <section className="course-description">
                    <h2>Description</h2>
                    <textarea name="description" placeholder="Course Description" value={course.description} onChange={handleInputChange}></textarea>
                </section>

                <section className="professor-info">
                    <h2>Professor</h2>
                    <input type="text" name="professor" placeholder="Professor Name" value={course.professor} onChange={handleInputChange} />
                </section>

                <section className="course-materials">
                    <h2>Course Materials</h2>
                    {course.materials.map((material, index) => (
                        <input key={material.id} type="text" value={material.title} placeholder="Material Title" onChange={(e) => handleMaterialChange(e, index)} />
                    ))}
                    <button type="button" onClick={addMaterialField}>Add Material</button>
                </section>

                <section className="exam-info">
                    <h2>Exams & Assignments</h2>
                    <input type="text" name="exams" placeholder="Exams & Assignments" value={course.exams} onChange={handleInputChange} />
                </section>

                <button type="submit">Add Course</button>
            </form>
        </div>
    );
};

export default AddCourse;
