import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Global.css';

const AcademicProgramOverview = () => {
    const [programInfo, setProgramInfo] = useState(null);

    useEffect(() => {
        const fetchProgramInfo = async () => {
            try {
                const studentId = localStorage.getItem('user_id');

                if (studentId) {
                    const response = await fetch(`https://sxn4468.uta.cloud/wdm/student.php?student_id=${studentId}`);
                    const data = await response.json();
                    setProgramInfo(data.program);
                } else {
                    console.error('No student ID found in local storage');
                }
            } catch (error) {
                console.error('Error fetching program information:', error);
            }
        };

        fetchProgramInfo();
    }, []);

    return (
        <div className="program-overview-container">
            <h1>Academic Program Overview</h1>
            {programInfo ? (
                <>
                    <section className="program-description">
                        <h2>Program Description</h2>
                        <p>{programInfo.description}</p>
                    </section>

                    <section className="curriculum-overview">
                        <h2>Curriculum Overview</h2>
                        <p>{programInfo.curriculum_overview}</p>
                    </section>

                    <section className="faculty-information">
                        <h2>Faculty Information</h2>
                        <p>{programInfo.faculty}</p>
                    </section>

                    <section className="admission-requirements">
                        <h2>Admission Requirements</h2>
                        <p>{programInfo.requirements}</p>
                    </section>
                    <section className="admission-requirements">
                        <h2>Duration</h2>
                        <p>{programInfo.duration}</p>
                    </section>

                </>
            ) : (
                <p>Loading program information...</p>
            )}
            <Link to="/student-dashboard" className="back-to-dashboard">Back to Dashboard</Link>
        </div>
    );
};

export default AcademicProgramOverview;
