import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const ForgotPasswordPage = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');

  const handleResetPassword = async () => {
    try {
      // Implement your logic to reset the password here
      // This could involve sending a reset link to the provided email address
      // For example:
      // const response = await fetch('YOUR_RESET_ENDPOINT', {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json'
      //   },
      //   body: JSON.stringify({ email }),
      // });
      // const data = await response.json();
      
      // Placeholder for demonstration purposes
      alert(`Password reset link sent to ${email}`);
      navigate('/'); // Redirect to the home page after sending the reset link
    } catch (error) {
      console.error('Error:', error);
      alert('An unexpected error occurred.');
    }
  };

  const containerStyles = {
    fontFamily: 'Arial, sans-serif',
    background: 'linear-gradient(45deg, rgb(130, 45, 52), #f3f7f6)',
    height: '100vh',
    margin: '0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const resetContainerStyles = {
    background: '#fff',
    borderRadius: '10px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
    padding: '20px',
    width: '300px',
    textAlign: 'center',
  };

  const inputStyles = {
    width: '80%',
    padding: '10px',
    margin: '10px 0',
    border: '2px solid #6c272e',
    borderRadius: '5px',
    fontSize: '16px',
    outline: 'none',
  };

  const buttonStyles = {
    backgroundColor: '#761031',
    color: '#fff',
    border: 'none',
    padding: '10px 20px',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '18px',
    transition: 'background-color 0.3s',
  };

  const linkStyles = {
    color: '#000',
    textDecoration: 'none',
  };

  return (
    <div style={containerStyles}>
      <div className="reset-container" style={resetContainerStyles}>
        <h2 style={{ color: '#710d1c' }}>Forgot Password</h2>
        <div className="input-container">
          <input
            type="text"
            placeholder="Enter Email"
            style={inputStyles}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="input-container">
          <button type="button" onClick={handleResetPassword} style={buttonStyles}>
            Reset Password
          </button>
          <Link to="/login" style={linkStyles}>
            Back to Login
          </Link>
        </div>
        <Link to="/" style={linkStyles}>
          Go back to Home
        </Link>
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
