import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import './Global.css'; // Import the CSS file
import React, { useState, useEffect } from 'react';


const InstructorDashboard = () => {

  const [editMode, setEditMode] = useState(false);
  const [profile, setProfile] = useState({});

  useEffect(() => {
    const userId = localStorage.getItem('user_id');
    fetchUserData(userId);
  }, []);

  const fetchUserData = async (userId) => {
    try {
      const response = await fetch(`https://sxn4468.uta.cloud/wdm/users.php?user_id=${userId}`);
      const data = await response.json();
      if (data && data.user) {
        setProfile(data.user);
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  const handleEditToggle = () => {
    setEditMode(!editMode);
  };

  const handleInputChange = (e) => {
    setProfile({ ...profile, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://sxn4468.uta.cloud/wdm/users.php', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(profile),
      });
      const data = await response.json();
      if (data.message === 'User updated successfully') {
        setEditMode(false);
      }
    } catch (error) {
      console.error('Error updating user data:', error);
    }
  };

  return (
    <div>
      <title>Instructor Dashboard</title>
      <link rel="stylesheet" type="text/css" href="Global.css" />
      <header>
        <nav>
          <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/messages">Messages</Link></li>
            <li><Link to="/settings">Settings</Link></li>
            <li><Link to="/login">Logout</Link></li>
            <div className="dashboard-title">
              DASHBOARD
            </div>
          </ul>
        </nav>
      </header>
      <div className="dashboard-container">
        <div className="dashboard-container">
          <div className="welcome-text">
            <h2>Welcome, Instructor!</h2>
          </div>
          <div className="dashboard-links">
            <ul className="circle-container">
              <li>
                <div className="circle">
                  <Link to="/instructor-courses">Course Management</Link>
                </div>
              </li>

              <li>
                <div className="circle">
                  <Link to="/instructor4">Student Progress Monitoring</Link>
                </div>
              </li>
            </ul>
            <Link to="/chat" className="chat-button">
              <img src="Images/chat-icon.png" alt="Chat" className="chat-icon" />
            </Link>
          </div>
          <div className="profile-card">
            {editMode ? (
              <form onSubmit={handleSubmit}>
                <input type="text" name="name" value={profile.name} onChange={handleInputChange} />
                <input type="email" name="email" value={profile.email} onChange={handleInputChange} />
                <input type="text" name="program" value={profile.program} onChange={handleInputChange} />
                <button type="submit">Save</button>
                <button onClick={handleEditToggle}>Cancel</button>
              </form>
            ) : (
              <>
                <h3>{profile.name}</h3>
                <p>Email: {profile.email}</p>
                <p>Name: {profile.name}</p>
                <p>Role: Instructor</p>
                <button onClick={handleEditToggle}>Edit</button>
              </>
            )}
          </div>
        </div>
      </div>
      <footer>
        <div className="footer-links">
          <ul>
            <li><Link to="/privacy">Privacy Policy</Link></li>
            <li><Link to="/faq">FAQ</Link></li>
            <li><Link to="/about">About Us</Link></li>
            <li><Link to="/contact">Contact Us</Link></li>
          </ul>
        </div>
      </footer>
    </div>
  );
};

export default InstructorDashboard;


