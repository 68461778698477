import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Global.css';

const InstructorCourses = () => {
    const [courses, setCourses] = useState([]);
    useEffect(() => {
        const fetchCourses = async () => {
            const instructor_id = localStorage.getItem('user_id'); // Assuming instructor_id is stored in local storage as 'user_id'

            try {
                const response = await fetch(`https://sxn4468.uta.cloud/wdm/instructor_courses.php?user_id=${instructor_id}`);
                const data = await response.json();
                if (data.courses) { // Use 'courses' instead of 'teachingCourses'
                    setCourses(data.courses);
                }
            } catch (error) {
                console.error('Error fetching courses:', error);
            }
        };

        fetchCourses();
    }, []);

    return (
        <div className="course-catalog">
            <header className="catalog-header">
                <h1>Instructor Courses</h1>
                <a href="/add-course">Add Instructor Courses</a>

            </header>
            <div className="course-list">
                {courses.length > 0 ? (
                    courses.map((course) => (
                        <Link to={`/edit-course?course_id=${course.course_id}`} className="course-link" key={course.course_id}>
                            <div className="course-card">
                                <h2 className="course-title">{course.title}</h2>
                                <p className="course-description">{course.description}</p>
                                <p className="course-professor">Faculty: {course.faculty}</p>
                            </div>
                        </Link>
                    ))
                ) : (
                    <p>No courses found.</p>
                )}
            </div>
        </div>
    );
};

export default InstructorCourses;
