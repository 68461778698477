import React, { useState } from 'react';
import './Global.css'; // Import the CSS file

// Placeholder data
const courses = [
    { id: 1, name: "Introduction to Psychology" },
    { id: 2, name: "Principles of Economics" },
    // Add more courses as needed
];

const assessments = [
    { id: 1, name: "Midterm Exam", courseId: 1, students: [{ id: 1, name: "Student A" }, { id: 2, name: "Student B" }] },
    { id: 2, name: "Final Project", courseId: 2, students: [{ id: 3, name: "Student C" }, { id: 4, name: "Student D" }] },
    // Add more assessments as needed
];

const InstructorGrades = () => {
    const [selectedCourse, setSelectedCourse] = useState('');
    const [selectedAssessment, setSelectedAssessment] = useState(null);

    const handleCourseChange = (e) => {
        setSelectedCourse(e.target.value);
        setSelectedAssessment(null);
    };

    const handleAssessmentChange = (e) => {
        const assessment = assessments.find(a => a.id === parseInt(e.target.value));
        setSelectedAssessment(assessment);
    };

    const renderAssessmentDropdown = () => {
        const filteredAssessments = assessments.filter(a => a.courseId === parseInt(selectedCourse));
        return (
            <select onChange={handleAssessmentChange} defaultValue="">
                <option value="" disabled>Select an assessment</option>
                {filteredAssessments.map(a => (
                    <option key={a.id} value={a.id}>{a.name}</option>
                ))}
            </select>
        );
    };

    const renderStudentGrades = () => {
        return selectedAssessment && selectedAssessment.students.map(student => (
            <div key={student.id} className="student-grade">
                <span>{student.name}</span>
                <input type="text" placeholder="Enter grade" />
            </div>
        ));
    };

    return (
        <div className="grade-assessments-container">
            <h1>Grade Assessments & Exams</h1>
            <div className="selection-container">
                <select onChange={handleCourseChange} defaultValue="">
                    <option value="" disabled>Select a course</option>
                    {courses.map(course => (
                        <option key={course.id} value={course.id}>{course.name}</option>
                    ))}
                </select>
                {selectedCourse && renderAssessmentDropdown()}
            </div>
            <div className="grades-container">
                {renderStudentGrades()}
            </div>
        </div>
    );
};

export default InstructorGrades;
