import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

const QAOReports = () => {
    const [grades, setGrades] = useState([]);

    useEffect(() => {
        axios.get('https://sxn4468.uta.cloud/wdm/qao.php')
            .then(response => {
                setGrades(response.data.grades);
            })
            .catch(error => {
                console.error('Error fetching grades', error);
            });
    }, []);

    // Function to calculate overall performance
    const calculateOverallPerformance = () => {
        if (grades.length === 0) {
            return null;
        }

        // Calculate average grade
        const gradeSum = grades.reduce((sum, grade) => {
            if (grade.grade) {
                return sum + parseFloat(grade.grade);
            }
            return sum;
        }, 0);
        const averageGrade = (gradeSum / grades.length).toFixed(2);

        // Count the number of remarks
        const remarksCount = grades.filter(grade => grade.remarks).length;

        return {
            averageGrade,
            remarksCount,
        };
    };

    const overallPerformance = calculateOverallPerformance();

    return (
        <div>
            <header>
                <nav>
                    <ul>
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/settings">Settings</Link></li>
                        <li><Link to="/login">Logout</Link></li>
                        <div className="dashboard-title">
                            Student Performance Analytics
                        </div>
                    </ul>
                </nav>
            </header>
            <div className="overall-performance-container">
                <h1>Overall Performance Report</h1>
                {overallPerformance && (
                    <>
                        <p>Average Grade: {overallPerformance.averageGrade}</p>
                        <p>Remarks Count: {overallPerformance.remarksCount}</p>
                    </>
                )}
            </div>
        </div>
    );
};

export default QAOReports;
