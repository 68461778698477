import React from 'react';
import { Link } from 'react-router-dom';
import './Global.css'; // Make sure to import your Global.css file

const Notification = () => {
  return (
    <div>
      <head>
        <title>Notification</title>
        <link rel="stylesheet" type="text/css" href="Global.css" />
        <style
          dangerouslySetInnerHTML={{
            __html: `
              body {
                background: linear-gradient(45deg, rgb(130, 45, 52), #f3f7f6);
                font-family: Arial, sans-serif;
                margin: 20px;
                padding: 0;
              }
              h1 {
                text-align: center;
              }
              ul {
                list-style: none;
                padding: 0;
              }
              /*li {
                margin-bottom: 20px;
                border: 1px solid #ddd;
                padding: 10px;
                background-color: #f9f9f9;
              }*/
            `,
          }}
        />
      </head>
      <body>
        <header>
          <nav>
            <ul>
              <li><Link to="/">Home</Link></li>
              <li><Link to="/settings">Settings</Link></li>
              <li><Link to="/notification">Notifications</Link></li>
              <li><Link to="/login">Logout</Link></li>
            </ul>
          </nav>
        </header>
        <h1>Notifications</h1>
        <ul>
          <li>
            <h2>Notification 1</h2>
            <p>This is the content of notification 1.</p>
          </li>
          <li>
            <h2>Notification 2</h2>
            <p>This is the content of notification 2.</p>
          </li>
        </ul>
        <footer>
          <div className="footer-links">
            <ul>
              <li><Link to="/privacy">Privacy Policy</Link></li>
              <li><Link to="/faqs">FAQ</Link></li>
              <li><Link to="/about">About Us</Link></li>
              <li><Link to="/contact">Contact Us</Link></li>
            </ul>
          </div>
        </footer>
      </body>
    </div>
  );
};

export default Notification;
