import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Global.css';
import { Link, useNavigate } from 'react-router-dom';

const MessagesPage = () => {
    const [users, setUsers] = useState([]);
    const [currentUserId, setCurrentUserId] = useState(localStorage.getItem('userId'));
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1);
    };

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await axios.get('https://sxn4468.uta.cloud/wdm/messages.php?action=getUsers');
                setUsers(response.data.users);
                setIsLoading(false);
            } catch (err) {
                setError(err);
                setIsLoading(false);
            }
        };

        fetchUsers();
    }, []);

    useEffect(() => {
        if (selectedUserId) {
            const fetchMessages = async () => {
                try {
                    const response = await axios.get(`https://sxn4468.uta.cloud/wdm/messages.php?action=getMessages&user_id=${currentUserId}`);
                    setMessages(response.data.messages.filter(msg => msg.sender_id === selectedUserId || msg.receiver_id === selectedUserId));
                } catch (err) {
                    setError(err);
                }
            };

            fetchMessages();
        }
    }, [selectedUserId, currentUserId]);

    const sendMessage = async () => {
        if (!newMessage.trim()) return;

        try {
            const response = await axios.post('https://sxn4468.uta.cloud/wdm/messages.php?action=sendMessage', {
                sender_id: currentUserId,
                receiver_id: selectedUserId,
                message_text: newMessage,
            });

            console.log('SendMessage Response:', response.data);

            setMessages(prevMessages => [
                ...prevMessages,
                { sender_id: currentUserId, receiver_id: selectedUserId, message_text: newMessage },
            ]);

            setNewMessage('');
        } catch (err) {
            setError(err);
            console.error('SendMessage Error:', err);
        }
    };

    return (
        <div>
            <header>
                <nav>
                    <ul>
                        <li><button className="back-button" onClick={goBack}>Back</button></li>
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/settings">Settings</Link></li>
                        <li><Link to="/login">Logout</Link></li>
                        <div className="dashboard-title">
                            DASHBOARD
                        </div>
                    </ul>
                </nav>
            </header>
            <div className="messages-page">
                <div className="sidebar">
                    {users.map(user => (
                        <div key={user.user_id} className="user" onClick={() => setSelectedUserId(user.user_id)}>
                            {user.name}
                        </div>
                    ))}
                </div>

                <div className="chat-area">
                    {selectedUserId ? (
                        <>
                            <div className="messages">
                                {messages.map((msg, index) => (
                                    <div key={index} className={`message ${msg.sender_id === currentUserId ? 'sent' : 'received'}`}>
                                        {msg.message_text}
                                    </div>
                                ))}
                            </div>
                            <div className="message-input">
                                <input type="text" value={newMessage} onChange={(e) => setNewMessage(e.target.value)} />
                                <button onClick={sendMessage}>Send</button>
                            </div>
                        </>
                    ) : (
                        <div className="new-chat-message">
                            Start a new chat and send a message.
                        </div>
                    )}
                </div>
                {isLoading && <p>Loading...</p>}
                {error && <p>Error loading data: {error.message}</p>}
            </div>
        </div>
    );
};

export default MessagesPage;
