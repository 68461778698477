import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Global.css';

const CourseCatalog = () => {
    const [courses, setCourses] = useState([]);

    useEffect(() => {
        const fetchCourses = async () => {
            try {
                const response = await fetch('https://sxn4468.uta.cloud/wdm/courses.php');
                const data = await response.json();
                setCourses(data.courses);
            } catch (error) {
                console.error('Error fetching course data:', error);
            }
        };

        fetchCourses();
    }, []);

    return (
        <div className="course-catalog">
            <header className="catalog-header">
                <h1>Course Catalog</h1>
            </header>
            <div className="course-list">
                {courses.map((course) => (
                    <Link
                        to={{
                            pathname: '/enroll-course',
                            search: `?course_id=${course.course_id}`,
                        }}
                        className="course-link"
                        key={course.course_id}
                    >
                        <div className="course-card">
                            <h2 className="course-title">{course.title}</h2>
                            <p className="course-description">{course.description}</p>
                            <p className="course-professor">Faculty: {course.faculty}</p>
                        </div>
                    </Link>
                ))}
            </div>
        </div>
    );
};

export default CourseCatalog;
