import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Widget, addResponseMessage, addUserMessage } from 'react-chat-widget';
import 'react-chat-widget/lib/styles.css';

const ChatPage = () => {
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!initialized) {
      addResponseMessage("Welcome to our chat! How can we assist you?");
      setInitialized(true);
    }
  }, [initialized]);

  const handleNewUserMessage = async (newMessage) => {
    addUserMessage(newMessage); // Display user's message in the chat
    try {
      // Simulate interaction with a backend (replace with actual API call)
      const botResponse = await simulateBotResponse(newMessage);
      addResponseMessage(botResponse);
    } catch (error) {
      console.error('Error sending message to chatbot:', error);
    }
  };

  // Simulated API call to interact with the bot (replace with actual API call)
  const simulateBotResponse = async (userMessage) => {
    // Simulate bot's response based on user's message
    return `Bot received: "${userMessage}". Bot response goes here.`;
  };

  return (
    <div>
      <title>Chat Page</title>
      <header>
        {/* Include any necessary header content */}
        <nav>
          <ul>
            <li><Link to="/home">Home</Link></li>
            <li><Link to="/messages">Messages</Link></li>
            <li><Link to="/login">Logout</Link></li>
            {/* Other navigation links */}
          </ul>
        </nav>
      </header>
      <div className="chat-container">
        <Widget
          handleNewUserMessage={handleNewUserMessage}
          title="Chat"
          subtitle="Talk to us"
        />
      </div>
      <footer>
        {/* Include any necessary footer content */}
        <div className="footer-links">
          <ul>
            <li><Link to="/privacy">Privacy Policy</Link></li>
            <li><Link to="/faq">FAQ</Link></li>
            {/* Other footer links */}
          </ul>
        </div>
      </footer>
    </div>
  );
};

export default ChatPage;
