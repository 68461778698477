import React from 'react';
import { Link } from 'react-router-dom';
import './Global.css';

const PCCurriculum = () => {

    const handleSubmit = (e) => {
        e.preventDefault();
    };

    return (
        <div className="curriculum-development">
            <title>Curriculum Development</title>

            <div className="content-container">
                <h1>Curriculum Developments and Updates</h1>

                <a href="/add-course" class="no-underline">
                    <section className="new-course">
                        <h2>Add New Course</h2>
                        <p>Add new courses to the curriculum, specifying details and content.</p>
                        <button type="submit">Add Course</button>
                    </section>
                </a>
            </div>

        </div>
    );
};

export default PCCurriculum;
