import React, { useState, useEffect } from 'react';


const CoursesPage = () => {

    const API_URL = 'https://sxn4468.uta.cloud/wdm/courses.php';

    const fetchCourses = async () => {
        try {
            const response = await fetch(API_URL);
            if (!response.ok) throw new Error('Network response was not ok');
            return await response.json();
        } catch (error) {
            console.error('Error fetching courses:', error);
        }
    };

    const updateCourse = async (course) => {
        try {
            const response = await fetch(API_URL, {
                method: 'POST', // Assuming POST is used for update
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(course),
            });
            if (!response.ok) throw new Error('Network response was not ok');
            return await response.json();
        } catch (error) {
            console.error('Error updating course:', error);
        }
    };

    const deleteCourse = async (courseId) => {
        try {
            const response = await fetch(API_URL, {
                method: 'DELETE', // Assuming DELETE is used for deletion
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ course_id: courseId }),
            });
            if (!response.ok) throw new Error('Network response was not ok');
            return await response.json();
        } catch (error) {
            console.error('Error deleting course:', error);
        }
    };
    const [courses, setCourses] = useState([]);
    const [editingCourse, setEditingCourse] = useState(null);
    useEffect(() => {
        fetchCourses().then(data => {
            if (data && data.courses) {
                setCourses(data.courses);
            }
        });
    }, []);

    const handleEditCourse = (course) => {
        setEditingCourse(course);
    };

    const handleUpdateCourse = (updatedCourse) => {
        updateCourse(updatedCourse).then(() => {
            setCourses(courses.map(course => course.course_id === updatedCourse.course_id ? updatedCourse : course));
            setEditingCourse(null);
        });
    };

    return (
        <div className="courses-page">
            <h1>Courses</h1>
            {courses.map(course => (
                <CourseItem key={course.course_id} course={course} onEditCourse={handleEditCourse} />
            ))}
            {editingCourse && (
                <EditCourseModal
                    course={editingCourse}
                    onUpdateCourse={handleUpdateCourse}
                    onClose={() => setEditingCourse(null)}
                />
            )}
            <button onClick={() => window.location.href = '/add-course'}>Add Course</button>
        </div>
    );
};

const CourseItem = ({ course, onEditCourse }) => {
    return (
        <div className="course-item">
            <h2>{course.title}</h2>
            <p>{course.description}</p>
            <button onClick={() => onEditCourse(course)}>Edit</button>
        </div>
    );
};


const EditCourseModal = ({ course, onUpdateCourse, onClose }) => {
    const [editedCourse, setEditedCourse] = useState(course);

    const handleInputChange = (e) => {
        setEditedCourse({ ...editedCourse, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onUpdateCourse(editedCourse);
    };

    return (
        <div className="edit-course-modal">
            <form onSubmit={handleSubmit}>
                {/* Similar input fields and structure as in AddCourse, but with initial values set from editedCourse */}
                <button type="submit">Save Changes</button>
                <button type="button" onClick={onClose}>Cancel</button>
            </form>
        </div>
    );
};


export default CoursesPage;
